// src/features/cart/cartSlice.js
import { createSlice } from '@reduxjs/toolkit';

// Fonction pour calculer le total TTC
function calculateTotalTTC(items) {
  return items.reduce((acc, item) => acc + item.price * item.quantity, 0);
}

const initialState = {
  items: JSON.parse(localStorage.getItem('cart')) || [], // Charger les articles depuis localStorage
  totalTTC: calculateTotalTTC(JSON.parse(localStorage.getItem('cart')) || []), // Calcul initial du total TTC
  status: 'new', // Statut de la commande commence par 'new'
  orderId: null, // ID de la commande active
  error: null,
  isGuest: !localStorage.getItem('user'), // Vérifier si l'utilisateur est connecté ou non
  groupOrderId: null, // ID de la commande groupée
  groupOrderParticipants: [], // Liste des participants à la commande groupée
};

function detectMenus(items) {
  const categoryCounts = items.reduce((acc, item) => {
    const category = item.category.toLowerCase(); // Normaliser les catégories
    acc[category] = (acc[category] || 0) + item.quantity;
    return acc;
  }, {});

  const detectedMenus = {
    simpleMenuCount: 0, // Entrée + Plat
    platDessertMenuCount: 0, // Plat + Dessert
    fullMenuCount: 0, // Entrée + Plat + Dessert
  };

  // Détection des menus complets (Entrée + Plat + Dessert)
  while (
    (categoryCounts["entrée"] || 0) > 0 &&
    (categoryCounts["plat"] || 0) > 0 &&
    (categoryCounts["dessert"] || 0) > 0
  ) {
    detectedMenus.fullMenuCount += 1;
    categoryCounts["entrée"] -= 1;
    categoryCounts["plat"] -= 1;
    categoryCounts["dessert"] -= 1;
  }

  // Détection des menus simples (Entrée + Plat)
  while (
    (categoryCounts["entrée"] || 0) > 0 &&
    (categoryCounts["plat"] || 0) > 0
  ) {
    detectedMenus.simpleMenuCount += 1;
    categoryCounts["entrée"] -= 1;
    categoryCounts["plat"] -= 1;
  }

  // Détection des menus Plat + Dessert
  while (
    (categoryCounts["plat"] || 0) > 0 &&
    (categoryCounts["dessert"] || 0) > 0
  ) {
    detectedMenus.platDessertMenuCount += 1;
    categoryCounts["plat"] -= 1;
    categoryCounts["dessert"] -= 1;
  }
  return detectedMenus;
}

// Fonction pour calculer le total TTC avec des prix fixes pour les menus
// Fonction pour calculer le total TTC avec des prix fixes pour les menus
function calculateTotalWithMenus(items) {
  const menus = detectMenus(items);

  let menuTotal = 0;
  const itemsClone = items.map((item) => ({ ...item }));

  // Calcul des prix pour les menus complets (Entrée + Plat + Dessert)
  if (menus.fullMenuCount > 0) {
    menuTotal += menus.fullMenuCount * 11.90;
    for (let i = 0; i < menus.fullMenuCount; i++) {
      const entréeIndex = itemsClone.findIndex(
        (item) => item.category.toLowerCase() === "entrée" && item.quantity > 0
      );
      const platIndex = itemsClone.findIndex(
        (item) => item.category.toLowerCase() === "plat" && item.quantity > 0
      );
      const dessertIndex = itemsClone.findIndex(
        (item) => item.category.toLowerCase() === "dessert" && item.quantity > 0
      );
      if (entréeIndex !== -1) itemsClone[entréeIndex].quantity -= 1;
      if (platIndex !== -1) itemsClone[platIndex].quantity -= 1;
      if (dessertIndex !== -1) itemsClone[dessertIndex].quantity -= 1;
    }
  }

  // Calcul des prix pour les menus simples (Entrée + Plat)
  if (menus.simpleMenuCount > 0) {
    menuTotal += menus.simpleMenuCount * 9.90;
    for (let i = 0; i < menus.simpleMenuCount; i++) {
      const entréeIndex = itemsClone.findIndex(
        (item) => item.category.toLowerCase() === "entrée" && item.quantity > 0
      );
      const platIndex = itemsClone.findIndex(
        (item) => item.category.toLowerCase() === "plat" && item.quantity > 0
      );
      if (entréeIndex !== -1) itemsClone[entréeIndex].quantity -= 1;
      if (platIndex !== -1) itemsClone[platIndex].quantity -= 1;
    }
  }

  // Calcul des prix pour les menus Plat + Dessert
  if (menus.platDessertMenuCount > 0) {
    menuTotal += menus.platDessertMenuCount * 9.90;
    for (let i = 0; i < menus.platDessertMenuCount; i++) {
      const platIndex = itemsClone.findIndex(
        (item) => item.category.toLowerCase() === "plat" && item.quantity > 0
      );
      const dessertIndex = itemsClone.findIndex(
        (item) => item.category.toLowerCase() === "dessert" && item.quantity > 0
      );
      if (platIndex !== -1) itemsClone[platIndex].quantity -= 1;
      if (dessertIndex !== -1) itemsClone[dessertIndex].quantity -= 1;
    }
  }

  // Calcul du total avant réduction
  const totalBeforeDiscount = items.reduce((acc, item) => acc + item.price * item.quantity, 0);

  // Calcul des articles hors menu
  const remainingTotal = itemsClone.reduce((acc, item) => acc + item.price * item.quantity, 0);

  const total = menuTotal + remainingTotal;

  // Montant économisé
  const discountAmount = totalBeforeDiscount - total;

  return {
    total: total.toFixed(2),
    menus,
    totalBeforeDiscount: totalBeforeDiscount.toFixed(2),
    discountAmount: discountAmount.toFixed(2),
  };
}



const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    // Synchroniser le panier depuis localStorage
    setCartFromLocalStorage: (state, action) => {
      const storedCart = action.payload || JSON.parse(localStorage.getItem('cart')) || [];
      state.items = storedCart;
      state.totalTTC = calculateTotalWithMenus(storedCart);
    },
    // Ajouter un article au panier
    addToCart: (state, action) => {
      const newItem = {
        ...action.payload,
        category: action.payload.category || "autre", // Vérifier que la catégorie est bien définie
      };
        
      const itemIndex = state.items.findIndex((item) => item.id === newItem.id);
      if (itemIndex !== -1) {
        state.items[itemIndex].quantity += 1;
      } else {
        state.items.push({ ...newItem, quantity: 1 });
      }
    
      state.totalTTC = calculateTotalWithMenus(state.items);
      localStorage.setItem("cart", JSON.stringify(state.items));
    },

    // Incrémenter la quantité d'un article
    incrementQuantity(state, action) {
      const itemIndex = state.items.findIndex(item => item.id === action.payload.id);
      if (itemIndex !== -1) {
        state.items[itemIndex].quantity += 1;
      }
      state.totalTTC = calculateTotalWithMenus(state.items);
      localStorage.setItem('cart', JSON.stringify(state.items));
    },
    // Décrémenter la quantité d'un article
    decrementQuantity(state, action) {
      const itemIndex = state.items.findIndex(item => item.id === action.payload.id);
      if (itemIndex !== -1 && state.items[itemIndex].quantity > 1) {
        state.items[itemIndex].quantity -= 1;
      }
      state.totalTTC = calculateTotalWithMenus(state.items);
      localStorage.setItem('cart', JSON.stringify(state.items));
    },
    // Retirer un article du panier
    removeFromCart(state, action) {
      state.items = state.items.filter(item => item.id !== action.payload.id);
      state.totalTTC = calculateTotalWithMenus(state.items);
      localStorage.setItem('cart', JSON.stringify(state.items));
    },
    // Réinitialiser le panier
    resetCart(state) {
      state.items = [];
      state.totalTTC = 0;
      state.orderId = null;
      state.groupOrderId = null;
      state.groupOrderParticipants = [];
      localStorage.removeItem('cart');
      localStorage.removeItem('currentOrder');
      localStorage.removeItem('cartExpiration');
    },
    // Retirer les articles non disponibles
    clearUnavailableItems(state) {
      state.items = state.items.filter(item => item.isAvailable);
      state.totalTTC = calculateTotalWithMenus(state.items);
      localStorage.setItem('cart', JSON.stringify(state.items));
    },
    // Vérifier l'expiration du panier
    checkCartExpiration(state) {
      const cartExpiration = localStorage.getItem('cartExpiration');
      if (cartExpiration && new Date().getTime() > cartExpiration) {
        state.items = [];
        state.totalTTC = 0;
        state.orderId = null;
        state.groupOrderId = null;
        state.groupOrderParticipants = [];
        localStorage.removeItem('cart');
        localStorage.removeItem('currentOrder');
        localStorage.removeItem('cartExpiration');
      }
    },
    // Définir l'ID de la commande
    setOrderId(state, action) {
      state.orderId = action.payload;
    },
    // **Nouvelle action pour mettre à jour le panier**
    updateCart(state, action) {
      const newCart = action.payload;
      state.items = newCart;
      state.totalTTC = calculateTotalWithMenus(newCart);
      localStorage.setItem('cart', JSON.stringify(newCart));
    },
    // **Actions pour la commande groupée**
    startGroupOrder(state, action) {
      state.groupOrderId = action.payload.groupOrderId;
      state.groupOrderParticipants = action.payload.participants || [];
      // Optionnel : ajouter d'autres propriétés si nécessaire
    },
    addParticipantToGroupOrder(state, action) {
      state.groupOrderParticipants.push(action.payload.participant);
    },
    removeParticipantFromGroupOrder(state, action) {
      state.groupOrderParticipants = state.groupOrderParticipants.filter(
        participant => participant.id !== action.payload.id
      );
    },
    endGroupOrder(state) {
      state.groupOrderId = null;
      state.groupOrderParticipants = [];
    },
  }
});

export const {
  addToCart,
  incrementQuantity,
  decrementQuantity,
  removeFromCart,
  resetCart,
  clearUnavailableItems,
  checkCartExpiration,
  setOrderId,
  setCartFromLocalStorage,
  updateCart,
  startGroupOrder,
  addParticipantToGroupOrder,
  removeParticipantFromGroupOrder,
  endGroupOrder
} = cartSlice.actions;

export const selectCartItems = state => state.cart.items;
// Export explicite pour detectMenus
export { detectMenus,calculateTotalWithMenus };
export default cartSlice.reducer;
